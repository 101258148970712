import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { getByID, create, edit } from "../api/certificate.api";
import withLoading from "../hoc/withLoading";
import ImageUpload from "../components/ImageUpload";
import { showSuccessDialog, showEditDialog, showCreateDialog, showErrorDialog } from "../components/Dialog";


const CertificateManageAction = () => {
  const [isLoading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isValidDateRange, setIsValidDateRange] = useState(true);
  const [imageFiles, setImageFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [removedImages, setRemovedImages] = useState([]); // Track removed images
  const [newImages, setNewImages] = useState([]); // Newly uploaded images
  const [method, setMethod] = useState("");
  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    id: "",
    thc_thumbnail: "",
    is_active: true,
  });

  const isFormValid = () => {
    const { thc_thumbnail } = formData;
  
    if (!thc_thumbnail) {
      showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน')
      return false;
    }

    return true;
  };

  const handleImageUpload = (uploadedFiles) => {
    setNewImages([...newImages, ...uploadedFiles]);
  };

  const handleIsActiveChange = (e) => {
    setFormData({ ...formData, is_active: e.target.value === 'true' });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const fetchData = async (id) => {
    try {
      setLoading(true);
      const response = await getByID(id);
      if (response) {
        setFormData({
          id: response?.thc_id,
          name: response?.thc_thumbnail,
          is_active: response?.is_active,
        });

        if (response?.thc_image) {
          setImagePreviews([{
            src: response?.thc_image,
            name: `image_1`,
            id: 1,
          }])
        }
      } else {
        showErrorDialog('ไม่พบข้อมูล certificate').then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/certificate");
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/certificate");
        }
      });
    } finally {
      setLoading(false)
    }
  };

  const createCertificateData = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }
  
      setLoading(true);
  
      const response = await create(formData, newImages, removedImages);
  
      if (response) {
        await showSuccessDialog('เพิ่มข้อมูลสำเร็จ');
        navigate("/certificate");
      } else {
        await showErrorDialog('เกิดข้อผิดพลาด');
        navigate("/certificate");
      }
    } catch (error) {
      await showErrorDialog('เกิดข้อผิดพลาด');
      navigate("/certificate");
    } finally {
      setLoading(false);
    }
  };

  const updateCertificateData = async (id) => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return; 
      }
      setLoading(true);
      const response = await edit(id, formData, newImages, removedImages);;
      if (response) {
        await showSuccessDialog('แก้ไขข้อมูล');
        navigate("/certificate");
      } else {
        await showErrorDialog('เกิดข้อผิดพลาด');
        navigate("/certificate");
      }
      setLoading(false);
    } catch (error) {
      await showErrorDialog('เกิดข้อผิดพลาด');
      navigate("/certificate");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const method = urlParams.get("method");

    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate("/certificate");
    }

    if (method === "view" || method === "edit") {
      fetchData(id);
    }

    if (method === "view") {
      setIsView(true);
    }

    setMethod(method);
  }, []);

  return (
      <div className="container-fluid">
        <div className="box-white">
          <div className="mt-3">
            <h4 className="px-3 mb-2">ใบรับรอง | สร้างใบรับรอง</h4>
            <h5 className="px-3 mb-2 text-header">สร้างใบรับรอง</h5>

            <Row className="mt-3 px-5">
              <Col xs={'auto'} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  ชื่อใบรับรอง 
                </label>
              </Col>
              <Col xs={12} md={4}>
                <input
                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.thc_thumbnail}
                  name="thc_thumbnail"
                  id="thc_thumbnail"
                  onChange={handleChange}
                />
              </Col>
            </Row>

            {/* 2 */}
            <Row className="mt-3 px-5">
              <Col xs={"auto"} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  ภาพตัวอย่าง
                </span>
              </Col>
              <Col xs={12} md={4}>
                {isView ? (
                  <div className="image-preview-container">
                    {imagePreviews.map((preview, index) => (
                      <div key={index + 1} className="image-preview">
                        <img
                          src={preview.src}
                          alt={preview.name}
                          width={'150px'}
                          height={'auto'}
                          className="image-preview-thumb"
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <ImageUpload
                    onUpload={handleImageUpload}
                    datDetailImage={() => {}}
                    dtStatusClear={false}
                    initialPreviews={imagePreviews} 
                    maxImages={1}
                  />
                )}
              </Col>
            </Row>

            <Row className="mt-3 px-5 align-self-center">
              <Col xs={'auto'} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  สถานะ {" "}
                </label>
              </Col>

              <Col sm={12} md={4} className="align-self-center px-5">
                <p className="wh-sp">
                  <Form.Check
                    className="px-5"
                    inline
                    type="radio"
                    label="ใช้งาน"
                    name="isActiveGroup"
                    id="Active"
                    value="true"
                    checked={formData.is_active === true}
                    onChange={handleIsActiveChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="ไม่ใช้งาน"
                    name="isActiveGroup"
                    id="Inactive"
                    value="false"
                    checked={formData.is_active === false}
                    onChange={handleIsActiveChange}
                  />
                </p>
              </Col>
            </Row>

            <Row className="mt-3 px-5">
              <Col xs={6} className="d-flex justify-content-start">
                <button
                  className="btn-cancel m-1 mt-5 flex-1"
                  onClick={() => {
                    navigate("/certificate");
                  }}
                >
                  ยกเลิก
                </button>{" "}
                {!isView ? (
                  <button
                    className="btn-save m-1 mt-5"
                    onClick={(e) => {
                      method === "create"
                        ? createCertificateData()
                        : updateCertificateData(id);
                    }}
                    // disabled={!isFormValid()}
                  >
                    บันทึก
                  </button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
  );
};

export default withLoading(CertificateManageAction);

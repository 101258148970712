import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const importInviteLearner = async (body) => {
  try {
    const response = await instance.post("/classroom/learner-invite", body);
    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`error durring fetch api`);
    }
  } catch (error) {}
};

export const getLearnerProgress = async (query) => {
  try {
    const learner_id = query.learner_id
    const response = await instance.get(`/classroom/progress/${learner_id}`,{ params: query });

    if(response.status === 200){
      return response.data.data;
  }else{
      return null
  }
  } catch (error) {
    console.log(error)
    return null
  }
} 

export const getTeacherList = async (query)=> {
  try {
      const response = await instance.get(`/teacher`,{ params: query });

      if(response.status === 200){
          const data = response.data.data || [];
          const totalPages = response.data.total;
          return { data, totalPages };
      }else{
          return null
      }

    } catch (error) {
      console.log(error)
      return null
    }
};

export const getCertificateList = async (query)=> {
  try {
      const response = await instance.get(`${API_BASE_URL}/themecertificate`,{ params: query });

      if(response.status === 200){
          const data = response.data.data || [];
          const totalPages = response.data.total;
          return { data, totalPages };
      }else{
          return null
      }

    } catch (error) {
      console.log(error)
      return null
    }
};

export const startClassroom = async (cla_id) => {
  try {
    const toStart = { cla_id }
    const response = await instance.post(`/classroom//start-classroom`,toStart);
    if(response.status === 200){
        return response.data.data;
    }else{
        return null
    }
  } catch (error) {
    return null
  }
}

export const getList = async (query)=> {
    try {
        const response = await instance.get(`/classroom`,{ params: query });

        if(response.status === 200){
            const data = response.data.data || [];
            const totalPages = response.data.total;
            return { data, totalPages };
        }else{
            return null
        }

      } catch (error) {
        console.log(error)
        return null
      }
};

export const getByID = async (id) => {
    try {
        const response = await instance.get(`/classroom/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const create = async (data) => {
    try {
        const toCreate = {
          cla_name: data?.name,
          clc_startdate: data?.start,
          clc_enddate: data?.end,
          template_id: data?.cert_tempalte,
          subteacher_id: data?.teacher_sub,
          tea_id: data?.tea_id
        }

        const response = await instance.post(`/classroom`,toCreate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const edit = async (id,data)=> {
    try {
        const toUpdate = {
          cla_name: data?.name,
          clc_startdate: data?.start,
          clc_enddate: data?.end,
          template_id: data?.cert_tempalte,
          subteacher_id: data?.teacher_sub,
          tea_id: data?.tea_id
        }
        const response = await instance.put(`/classroom/${id}`,toUpdate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const remove = async (id) => {
  try {
      const response = await instance.delete(`/classroom/${id}`);
      if(response.status === 200){
          return response.data;
      }else{
          return null
      }
    } catch (error) {
      return null
    }
};

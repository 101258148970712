import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import headerDefinitions from "../tableheaders/classlesson.tableheader.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import helpers from "../helpers/function.helper";
import { getByID } from "../api/classchapter.api";
import { getClassroomByID, getList, remove } from "../api/classlesson.api";
import Swal from "sweetalert2";
import viewIcon from "../assets/icons/view.png";
import editIcon from "../assets/icons/edit.png";
import formIcon from "../assets/icons/form.png";
import downloadIcon from "../assets/icons/download.png";
import deleteIcon from "../assets/icons/delete.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import TableHeaderButton from "../components/TableHeaderButton";
import { showDeleteDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";
import './TeacherClassLesson.css'
import LoadingComponent from "../components/Loading";


const TeacherClassLesson = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const section_id = urlParams.get("cls_id");
  const classroom_id = urlParams.get("cla_id");

  const [keyword, setKeyword] = useState("");
  const [group, setGroup] = useState(null);
  const [isLoading, setLoading] = useState(true);
  // const [section, setSection] = useState(null);
  const [chapter, setChapter] = useState(null);
  const [classroom, setClassRoom] = useState(null);
  const [lessonGroup, setLessonGroup] = useState('');
  // -------------------- Data Table ----------------------------------------
  const headersData = headerDefinitions.columns;
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataTable, setDatatable] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const tableData = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(() => headersData, [headersData]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
      },
      useSortBy,
      usePagination
    );

  const toggleRowSelection = (Id) => {
    setSelectedRowIds((prev) => {
      if (prev.includes(Id)) {
        // If already selected, remove it
        return prev.filter((id) => id !== Id);
      } else {
        // Otherwise, add it
        return [...prev, Id];
      }
    });
  };

  const handleSort = async (columnId) => {
    // Will Sort By *accessor* in HeaderJSON File
    setSortConfig((prevSortConfig) => {
      let direction = "ASC";
      if (
        prevSortConfig.key === columnId &&
        prevSortConfig.direction === "ASC"
      ) {
        direction = "DESC";
      }
      return { key: columnId, direction };
    });
  };

  const handleNextPage = () => {
    setPageIndex((current) => {
      const nextPageIndex = current + 1;
      setCanNextPage(nextPageIndex < pageCount - 1);
      setCanPreviousPage(true);
      return nextPageIndex;
    });
  };

  const handlePreviousPage = () => {
    setPageIndex((current) => {
      const previousPageIndex = Math.max(0, current - 1);
      setCanPreviousPage(previousPageIndex > 0);
      setCanNextPage(true);
      return previousPageIndex;
    });
  };

  // -------------------- Data Table ----------------------------------------

  const clearSearch = () => {
    setKeyword("");
    setSortConfig({ key: "", direction: "" });
    setRowsPerPage(10);
    setPageIndex(0);
  };

  const fetchChapter = async () => {
    const data = await getByID(id);
    if (data) {
      setChapter(data);
    } else {
      showErrorDialog('ไม่พบข้อมูล Chapter').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/classmanage");
        }
      });
    }
  };

  const fetchClassroom = async () => {
    const data = await getClassroomByID(classroom_id);
    if (data) {
      console.log("Data received:", data);
      setClassRoom(data);
    } else {
      showErrorDialog('ไม่พบข้อมูล Classroom').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/classmanage");
        }
      });
    }
  };

  const handleGroupClick = async (value) => {
    setLoading(true);
    let newGroup = value;

    // If the same button is clicked, reset the group to empty
    if (group === value) {
      newGroup = ""; 
    }

    setGroup(newGroup); 

    // Use newGroup directly here instead of group
    await fetchData(newGroup);
  
  };

  const fetchData = async (newGroup) => {
    try {

      const { data, totalPages } = await getList({
        page_number: pageIndex,
        data_per_page: rowsPerPage,
        keyword: keyword,
        group: typeof newGroup === 'string' ? newGroup : "",
        sort_key: sortConfig.key,
        sort_order: sortConfig.direction,
        chapter_id: id,
      });
      if (data) {
        console.log("Data received:", data);
        setDatatable(data);
        setPageCount(totalPages);
        setCanNextPage(pageIndex < Math.ceil(totalPages / rowsPerPage) - 1);
        setCanPreviousPage(pageIndex > 0);

      setLoading(false)
      }
    } catch (error) {
      console.error("Fetch Data Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteData = async (rowdata) => {
    try {
      const result = await showDeleteDialog();
      if (!result.isConfirmed) {
        return; 
      }
      setLoading(true);
      const response = await remove(rowdata?.cll_id)
      if (response) {
        showSuccessDialog('ลบข้อมูล สำเร็จ')
      } else {
        showErrorDialog('เกิดข้อผิดพลาด')
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด')
    } finally {
      fetchData()
    }
  }


  useEffect(() => {
    fetchChapter();
    fetchClassroom();
    fetchData();
    setLessonGroup("posttest")
  }, [pageIndex, rowsPerPage, sortConfig]);

  return (

    <>
    {isLoading ? (
      <LoadingComponent/>
    ) : (
      <div className="container-fluid">
      <div className="box-white">
        <Row className="mb-3">
          <h2>
            <Link
              className="text-decoration-none"
              to={`/classsection/chapter?cla_id=${classroom_id}&cls_id=${section_id}`}
            >
              บทเรียนย่อย
            </Link>{" "}
            | {chapter?.clc_name}
          </h2>
          <Col md={"auto"}>
          <button
            className={`lesson-group-btn mx-1 ${group === "pretest" ? "active" : ""}`}
            onClick={() => handleGroupClick("pretest")}
          >
            <span className="mx-1">ทดสอบก่อนเรียน</span>
          </button>

          <button
            className={`lesson-group-btn mx-1 ${group === "learn" ? "active" : ""}`}
            onClick={() => handleGroupClick("learn")}
          >
            <span className="mx-1">เนื้อหา</span>
          </button>

          <button
            className={`lesson-group-btn mx-1 ${group === "posttest" ? "active" : ""}`}
            onClick={() => handleGroupClick("posttest")}
          >
            <span className="mx-1">ทดสอบหลังเรียน</span>
          </button>

          <button
            className={`lesson-group-btn mx-1 ${group === "homework" ? "active" : ""}`}
            onClick={() => handleGroupClick("homework")}
          >
            <span className="mx-1">การบ้าน</span>
          </button>
          </Col>
        </Row>
        <Row>
          <Col md={"auto"}>
          {classroom?.is_start !== true && (
            <button
            className="custom-btn btn-create mx-1"
            onClick={() =>
              navigate(
                `/chapter/lesson/action?clc_id=${chapter.clc_id}&cls_id=${section_id}&cla_id=${classroom_id}&group=${lessonGroup}&method=create`
              )
            }
          >
            <FontAwesomeIcon icon={faPlus} />{" "}
            <span className="mx-1">เพิ่มเนื้อหา</span>
          </button>
            )}

          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-center mx-2 py-3">
          <div className="d-none d-md-block">
            <span className="pr-2 align-self-center text-sm fw-bold">
              Show :{" "}
            </span>
            <span>
              <select
                style={{ border: "none", cursor: "pointer" }}
                className="select-page mr-2"
                value={rowsPerPage}
                onChange={(e) => setRowsPerPage(Number(e.target.value))}
              >
                <option value={10}>10</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={200}>200</option>
                <option value={500}>500</option>
                <option value={1000}>1000</option>
              </select>
            </span>
            <span>Entries</span>
          </div>
          <div className="d-flex search-container">
            <input
              type="text"
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="ค้นหา"
              className="search-input"
            />
            <button className="search-button" onClick={fetchData}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
        <div style={{ overflow: "auto" }}>
          {" "}
          <Table
            striped
            bordered
            {...getTableProps()}
            className="table text-center "
            style={{ borderCollapse: "collapse" }}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                      {/* Add a clickable sort icon */}
                      <TableHeaderButton
                        column={column}
                        sortConfig={sortConfig}
                        handleSort={handleSort}
                      />
                    </th>
                  ))}
                  <th>เครื่องมือ</th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {" "}
              <tr className="space-table"></tr>
              {rows.length > 0 ? (
                rows.map((row, rowIndex) => {
                  prepareRow(row);
                  const actualIndex = pageIndex * rowsPerPage + rowIndex + 1;
                  const isSelected = selectedRowIds.includes(row.original.id);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => toggleRowSelection(row.original.id)}
                      className={isSelected ? "selected-row" : ""}
                      style={isSelected ? { backgroundColor: "#f0f0f0" } : {}}
                    >
                      <td>{row.original?.cll_order ?? ""}</td>
                      <td>{row.original?.cll_name ?? ""}</td>
                      <td>{helpers.getLessonGroup(row.original?.cll_group) ?? ""}</td>
                      <td>{helpers.getLessonType(row.original?.cll_type) ?? ""}</td>
                      <td>{row.original?.cll_timer ?? ""}</td>
                      <td>{row.original?.cll_round ?? ""}</td>
                      <td>{row.original?.cll_point ?? ""}</td>
                      <td>
                        <span
                          style={{
                            color: row.original.is_active
                              ? "#87BD66"
                              : "#D95350",
                          }}
                        >
                          {helpers.getStatus(row.original.is_active)}
                        </span>
                      </td>
                      <td>
                        <button
                          className="mx-1 icon-btn"
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            navigate(
                              `/chapter/lesson/action/${row.original.cll_id}?clc_id=${chapter.clc_id}&cls_id=${section_id}&cla_id=${classroom_id}&group=${lessonGroup}&method=view`
                            );
                          }}
                        >
                          <img
                            src={viewIcon}
                            alt="View"
                            className="ic-actions"
                          />
                        </button>

                        {classroom?.is_start !== true && (
                          <>
                        <button
                          className="mx-1 icon-btn"
                          style={{ background: "transparent" }}
                          onClick={(e) => {
                            navigate(
                              `/chapter/lesson/action/${row.original.cll_id}?clc_id=${chapter.clc_id}&cls_id=${section_id}&cla_id=${classroom_id}&group=${lessonGroup}&method=edit`
                            );
                          }}
                        >
                          <img
                            src={editIcon}
                            alt="Edit"
                            className="ic-actions"
                          />
                        </button>

                        <button
                          className="mx-1 icon-btn"
                          style={{ background: "transparent" }}
                          onClick={() => deleteData(row.original)}
                        >
                          <img
                            src={deleteIcon}
                            alt="Delete"
                            className="ic-actions"
                          />
                        </button>                       
                          </>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr className="text-center">
                  <td colSpan={100} style={{ textAlignLast: "center" }}>
                    ไม่พบข้อมูล
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <div
          className="py-3 mx-2"
          style={{ display: "flex", justifyContent: "right" }}
        >
          {/* Pagination */}
          <div className="mt-2">
            <span>
              {`${pageIndex * rowsPerPage + 1} - ${
                (pageIndex + 1) * rowsPerPage
              } จาก ${pageCount} `}
            </span>
            <button
              className="mx-1 px-3 icon-btn"
              onClick={handlePreviousPage}
              disabled={!canPreviousPage}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <span> {pageIndex + 1}</span>
            <button
              className="mx-1 px-3 icon-btn"
              onClick={handleNextPage}
              disabled={!canNextPage}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
            <span>{Math.ceil(pageCount / rowsPerPage)}</span>
          </div>
        </div>
      </div>
    </div>
    )}
  </>
  );
};

export default TeacherClassLesson;

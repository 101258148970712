import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImportExcel from "../components/ExcelRenderer";
import { verifyTeacherImport, importTeacherData } from "../api/teacher.api";
import { getClassroomByID } from "../api/classsection.api";
import { importInviteLearner } from "../api/classroom.api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EnumRoles from "../constants/EnumRoles";
import { useUserContext } from '../contexts/UserContext';
import { showCreateDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";


const LearnerInvite = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const { id } = useParams();
  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [emptyCell, setEmptyCell] = useState(0);
  const [classroom, setClassRoom] = useState(null);


  const fetchData = async () => {
    console.log("Fetching data...");
    try {
      const data = await getClassroomByID(id);
      if (data) {
        console.log("Data received:", data);
        setClassRoom(data);
      } else {
        showErrorDialog('ไม่พบข้อมูล Classroom').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(`/classmanage/classsection/${id}`)
          }
        });
      }

    } catch (error) {
      console.error("Fetch Data Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImport = async (rows) => {
    // Resetting counters
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    // Preparing to store the parsed rows
    const parsedRows = rows
      .slice(1)
      .filter(
        (item) =>
          item[0] !== undefined &&
          item[1] !== undefined
      )
      .map((item) => ({
        learner_code: item[0],
        fullname: item[1]
      }));

    // Update the state with parsed rows
    await setImportData(parsedRows);

    // Set the total number of rows imported
    setTotalRow(rows.length - 1);

    // Check for empty cells and prepare a list of their locations
    const emptyCells = [];
    rows.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        if (!cell) {
          // Cell is empty or undefined
          emptyCells.push({ row: rowIndex + 1, column: cellIndex + 1 }); // Add 1 to make it human-readable
        }
      });
    });

    // Set the number of empty cells found
    setEmptyCell(emptyCells.length);

    // Alert the user if any empty cells are found
    if (emptyCells.length > 0) {
      showErrorDialog('กรุณากรอกข้อมูลในไฟล์ให้ครบถ้วน')
    }
    console.log(importData);
  };

  const handleDataDetail = (fileName) => {
    console.log("File name:", fileName);
    // You can handle the file name or other details here.
  };

  const verifyData = async () => {
    try {
      try {
        setVerifySuccess(0);
        setVerifySkip(0);
        setVerifyNew(0);
        setFailedRow([]);
        let create = 0;
        let success = 0;
        let skip = 0;
        let error = 0;
        const batchSize = 10;
        const failedArr = [];

        const dataToVerify = {
          import_data: importData,
        };

        await setLoading(true);
        const response = await verifyTeacherImport(dataToVerify);
        await setLoading(false);

        if (response) {
          success += response.data.success;
          skip += response.data.update;
          create += response.data.create;
          setTotalRow(response.data.total);
          const failedData = response.data.data;
          failedData.map((item) => {
            failedArr.push(item);
          });

          setVerifySuccess(response.data.total);
          setVerifySkip(skip);
          setVerifyNew(create);
          setFailedRow(failedArr);
          failedArr.length > 0 ? setIsVerify(true) : setIsVerify(false);

          if (failedArr.length < 1) {
            showSuccessDialog('ตรวจสอบข้อมูลสำเร็จ')
          }
        } else {
          error++;
          showErrorDialog('กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ')
          return;
        }
      } catch (error) {
        showErrorDialog('เกิดข้อผิดพลาด')
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด')
    }
  };

  const importDataExcel = async () => {
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      setTotalRow(importData.length);

      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }
        setLoading(true);

        const dataToImport = {
          import_data: importData,
          classroom_id: id,
          user_type: EnumRoles.LEARNER,
        };

        const response = await importInviteLearner(dataToImport);
        setLoading(false);

        if (response && response.data.status === "success") {
          showSuccessDialog('บันทึกข้อมูลเข้าสู่ระบบสำเร็จ')
          setIsVerify(false);
          setImportData(response?.data?.data ?? [])
          // navigate(`/classmanage/classsection/${id}`)
        } else {
          showErrorDialog('กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ')
        }

        setVerifySuccess(response?.data?.total || 0);
        setVerifySkip(response?.data?.skip || 0);
      
    } catch (error) {
      console.error("Error importing data:", error);
      showErrorDialog('เกิดข้อผิดพลาดระหว่างการนำเข้าข้อมูล')
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <h2>เพิ่มผู้เรียน  </h2>
        <h3 style={{ color: "#1254E0" }}>เพิ่มผู้เรียน | ห้องเรียน {classroom?.cla_name}</h3>
        <div className="row justify-content-start m-0 pt-3">
          <Row className="mt-3 px-5">
            <Col Col xs={"auto"} md={1} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                แบบฟอร์ม
              </span>
            </Col>
            <Col xs={12} md={4}>
              <ImportExcel
                onImport={handleImport}
                datDetailExcel={handleDataDetail}
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={"auto"}>
              <button
                className="btn-cancel m-1 mt-5"
                onClick={() => navigate(`/classmanage/classsection/${id}`)}
              >
                <span className="mx-1">ยกเลิก</span>
              </button>
              <button
                className="btn-save m-1 mt-5"
                onClick={() => importDataExcel()}
              >
                <span className="mx-1">สร้าง</span>
              </button>
            </Col>
          </Row>
        </div>
        <div
          className={`card-white mt-3  ${
            importData.length > 0 ? "d-block" : "d-none"
          } `}
        >
          <p className="fontReg font-weight-bold font-size03 colorGray01">
            หมายเหตุ: กรุณาตรวจสอบข้อมูลก่อนนำข้อมูลเข้าสู่ระบบ
          </p>
          {importData.length ? (
            <div className="row">
              <table className="student-scores">
                <thead>
                  <tr>
                    <th>รหัสผู้เรียน</th>
                    <th>ชื่อ - นามสกุล</th>
                    <th>หมายเหตุ</th>
                  </tr>
                </thead>
                <tbody>
                  {importData.map((item, index) => (
                    <tr key={index + 1}>
                      <td>{item?.learner_code}</td>
                      <td>{item?.fullname}</td>
                      <td>{item?.remark}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default LearnerInvite;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ImportExcel from "../components/ExcelRenderer";
import { importQuestionsData } from "../api/question.api";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { showCreateDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";

const TestImport = () => {
  const navigate = useNavigate();

  const [importData, setImportData] = useState([]);
  const [isVerify, setIsVerify] = useState(true);
  const [verifySuccess, setVerifySuccess] = useState(0);
  const [verifySkip, setVerifySkip] = useState(0);
  const [verifyNew, setVerifyNew] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [failedRow, setFailedRow] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [emptyCell, setEmptyCell] = useState(0);

  const isFormValid = () => {
    if(importData.length === 0){
      showErrorDialog('ไม่พบข้อมูลสำหรับนำเข้า');
      return false;
    }
  
    // All validations passed
    return true;
  };

  const handleImport = async (rows) => {
    // Resetting counters
    setVerifySuccess(0);
    setVerifySkip(0);
    setVerifyNew(0);
    // Preparing to store the parsed rows
    const parsedRows = rows
      .slice(1)
      .map((item) => ({
        que_name: item[0],
        que_ans_1: item[1],
        que_ans_2: item[2],
        que_ans_3: item[3],
        que_ans_4: item[4],
        que_ans: item[5],
      }));

      console.log(parsedRows)
    // Update the state with parsed rows
    await setImportData(parsedRows);

    // Set the total number of rows imported
    setTotalRow(rows.length - 1);

    const emptyCells = [];
    rows.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        if (!cell) {
          // Cell is empty or undefined
          emptyCells.push({ row: rowIndex + 1, column: cellIndex + 1 }); // Add 1 to make it human-readable
        }
      });
    });

    setEmptyCell(emptyCells.length);

    if (emptyCells.length > 0) {
      showErrorDialog('กรุณากรอกข้อมูลในไฟล์ให้ครบถ้วน')
    }
  };

  const handleDataDetail = (fileName) => {
    console.log("File name:", fileName);
    // You can handle the file name or other details here.
  };

  const importDataExcel = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      setVerifySuccess(0);
      setVerifySkip(0);
      setVerifyNew(0);
      setFailedRow([]);
      setTotalRow(importData.length);


      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }

        setLoading(true);

        const dataToImport = {
          import_data: importData,
        };

        const response = await importQuestionsData(dataToImport);
        setLoading(false);

        if (response && response.data.status === "success") {
          showSuccessDialog('บันทึกข้อมูลเข้าสู่ระบบสำเร็จ')
          setIsVerify(false);
          setImportData(response?.data?.data ?? [])
        } else {
          showErrorDialog('กรุณาตรวจสอบไฟล์ ก่อนบันทึกข้อมูลเข้าสู่ระบบ')
        }

        setVerifySuccess(response?.data?.total || 0);
        setVerifySkip(response?.data?.skip || 0);
    
    } catch (error) {
      console.error("Error importing data:", error);
      showErrorDialog('เกิดข้อผิดพลาดระหว่างการนำเข้าข้อมูล')
    }
  };

  return (
    <div className="container-fluid">
      <div className="box-white">
        <div className="mt-3">
          <h2>แบบทดสอบ | เพิ่มแบบทดสอบ</h2>
          <h5 className="px-3 mb-2 text-header">เพิ่มแบบทดสอบ</h5>
          <div className="row justify-content-start m-0 pt-3">
            <Row className="mt-3 px-5">
              <Col Col xs={"auto"} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  แบบฟอร์ม
                </label>
              </Col>
              <Col xs={12} md={4}>
                <ImportExcel
                  onImport={handleImport}
                  datDetailExcel={handleDataDetail}
                />
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={"auto"}>
                <button
                  className="btn-cancel m-1 mt-5"
                  onClick={() => navigate("/test-manage")}
                >
                  <span className="mx-1">ยกเลิก</span>
                </button>
                <button
                  disabled={importData.length === 0}
                  className="btn-save m-1 mt-5"
                  onClick={() => importDataExcel()}
                >
                  <span className="mx-1">สร้าง</span>
                </button>
              </Col>
            </Row>
          </div>
          <div
            className={`card-white mt-3  ${
              importData.length > 0 ? "d-block" : "d-none"
            } `}
          >
            <p className="fontReg font-weight-bold font-size03 colorGray01">
              หมายเหตุ: กรุณาตรวจสอบข้อมูลก่อนนำข้อมูลเข้าสู่ระบบ
            </p>
            {importData.length ? (
              <div className="row">
                <table className="student-scores">
                  <thead>
                    <tr>
                      <th>คำถาม</th>
                      <th>คำตอบข้อ 1</th>
                      <th>คำตอบข้อ 2</th>
                      <th>คำตอบข้อ 3</th>
                      <th>คำตอบข้อ 4</th>
                      <th>ข้อถูก</th>
                      <th>หมายเหตุ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {importData.map((item,index) => (
                      <tr key={index+1}>
                        <td>{item?.que_name}</td>
                        <td>{item?.que_ans_1}</td>
                        <td>{item?.que_ans_2}</td>
                        <td>{item?.que_ans_3}</td>
                        <td>{item?.que_ans_4}</td>
                        <td>{item?.que_ans}</td>
                        <td>{item?.remark}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestImport;
